import React, { useState } from "react"
import Image from "gatsby-image"

const Youtube = ({ id, thumb }) => {
  const [showVideo, setShowVideo] = useState(false)
  return (
    <div className="w-full mx-auto overflow-hidden rounded-xl">
      <div className="relative h-0" style={{ paddingBottom: "56.25%" }}>
        {id ? (
          showVideo ? (
            <iframe
              title="video"
              width="100%"
              height="315"
              src={`https://www.youtube.com/embed/${id}?autoplay=1&showinfo=0`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="absolute top-0 left-0 w-full h-full"
            ></iframe>
          ) : (
            <button
              className="w-full h-full bg-gray-900 cursor-pointer group"
              onClick={() => setShowVideo(true)}
              aria-label="Toista video"
            >
              {/* Play button */}
              <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center">
                <div className="z-10">
                  <svg width="109" height="73">
                    <path
                      d="M0 36.5C0 21.0226 0 13.2838 4.05359 7.92888C5.16141 6.46541 6.46541 5.16141 7.92888 4.05359C13.2838 0 21.0226 0 36.5 0H72.5C87.9774 0 95.7162 0 101.071 4.05359C102.535 5.16141 103.839 6.46541 104.946 7.92888C109 13.2838 109 21.0226 109 36.5V36.5C109 51.9774 109 59.7162 104.946 65.0711C103.839 66.5346 102.535 67.8386 101.071 68.9464C95.7162 73 87.9774 73 72.5 73H36.5C21.0226 73 13.2838 73 7.92888 68.9464C6.46541 67.8386 5.16141 66.5346 4.05359 65.0711C0 59.7162 0 51.9774 0 36.5V36.5Z"
                      className="text-black fill-current"
                    />
                    <path
                      d="M47 31.217V41.783C47 44.9741 47 46.5697 48.0305 47.1408C49.0609 47.7119 50.4139 46.8663 53.12 45.175L61.5728 39.892C64.0085 38.3697 65.2264 37.6085 65.2264 36.5C65.2264 35.3915 64.0085 34.6303 61.5728 33.108L61.5728 33.108L53.12 27.825C50.4139 26.1337 49.0609 25.2881 48.0305 25.8592C47 26.4303 47 28.0259 47 31.217Z"
                      className="text-yellow-500 fill-current"
                    />
                  </svg>
                </div>
              </div>

              {/* Thumbnail */}
              <Image fluid={thumb} style={{}} />
              {/* Hover overlay effect */}
              <div className="absolute top-0 bottom-0 left-0 right-0 bg-yellow-500 bg-opacity-0 group-hover:bg-opacity-25"></div>
            </button>
          )
        ) : (
          <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full bg-gray-800">
            <p className="text-2xl font-bold text-gray-500 text-bold">
              Video tulossa pian
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default Youtube
