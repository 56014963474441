import React from "react"
import { graphql } from "gatsby"
import Layout from "./default"
import CategoryTitle from "../components/CategoryTitle"
import VideoList from "../components/VideoList"
import CategoryList from "../components/CategoryList"
import Youtube from "../components/Youtube"

const VideoPage = ({ data }) => {
  const {
    title,
    poster,
    largePoster,
    description,
    youtubeVideoId,
    slug,
  } = data.contentfulVideo
  const {
    categoryVideos,
    slug: categorySlug,
  } = data.contentfulVideo.category[0]
  const pageData = {
    title: title,
    category: data.contentfulVideo.category[0],
    description: description && description.description,
    poster: poster,
    ogImage: poster.fixed.src,
  }
  // Get all videos in the same category except the current video
  const filteredCategoryVideos = categoryVideos.filter(
    video => video.slug !== slug
  )
  const filteredCategories = data.allContentfulCategory.edges.filter(
    ({ node }) => node.slug !== categorySlug
  )
  return (
    <Layout pageData={pageData}>
      <div className="relative z-20 -mt-4 row">
        <div className="col">
          <Youtube id={youtubeVideoId} thumb={largePoster.fluid} />
        </div>
      </div>
      <div className="mt-8 row md:mt-16">
        <div className="text-white col">
          <CategoryTitle title="Muut saman kategorian videot" />
          <VideoList
            videos={filteredCategoryVideos}
            categorySlug={categorySlug}
          />
        </div>
      </div>
      <div className="mt-8 row md:mt-16">
        <div className="text-white col">
          <CategoryTitle title="Muut kategoriat" />
          <CategoryList categories={filteredCategories} />
        </div>
      </div>
    </Layout>
  )
}

export default VideoPage

export const query = graphql`
  query($slug: String!) {
    # Slug comes available from gatsby-node.js's createPage method's context
    contentfulVideo(slug: { eq: $slug }) {
      title
      slug
      # description {
      #   description
      # }
      youtubeVideoId
      largePoster: poster {
        fluid(maxWidth: 1440) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      poster {
        fluid(maxWidth: 400, maxHeight: 225) {
          ...GatsbyContentfulFluid_withWebp
        }
        fixed(width: 1200, height: 630, cropFocus: FACE) {
          src
        }
      }
      category {
        shortTitle
        slug
        categoryVideos {
          slug
          title
          poster {
            fluid(maxWidth: 400, maxHeight: 225) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          # description {
          #   description
          # }
        }
      }
    }
    allContentfulCategory {
      edges {
        node {
          slug
          shortTitle
        }
      }
    }
  }
`
