import React from "react"
import { Link } from "gatsby"

const CategoryList = ({ categories }) => (
  <div className="grid grid-cols-1 gap-2 md:gap-4 md:grid-cols-3 lg:grid-cols-4">
    {categories.map(({ node }) => {
      return (
        <Link
          to={`/${node.slug}/`}
          className="flex px-4 py-3 leading-tight text-gray-400 bg-gray-900 rounded group hover:bg-yellow-500 hover:text-black"
          key={node.slug}
        >
          <span>{node.shortTitle}</span>
          <span className="pl-2 ml-auto text-yellow-500 group-hover:text-black">
            &rarr;
          </span>
        </Link>
      )
    })}
  </div>
)

export default CategoryList
